.woocommerce .quantity .qty {
    height: calc(1.5em + .75rem + 2px);
    //padding: .375rem 1.3rem;
    line-height: 1.5;
}

.woocommerce-description {
    background-color: rgba(255, 255, 255, 0.5);
    p {
        margin: 0;
    }
}

.shopping_cart_header {

    //display: none;
    @include media-breakpoint-up(md) {
        position: relative;
        display: block;
    }

    .nav .open>a,
    .nav .open>a:hover,
    .nav .open>a:focus {
        background-color: transparent !important;
        color: $primary;
    }

    a {
        @include transition(none);
    }
    .btn {
        color: #fff;
    }

    .header_cart {
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        background-image: url('../assets/img/carret.png');
        background-color: transparent !important;
        background-size: 40px auto;
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 40px;
        height: 40px;
        position: relative;

        @include media-breakpoint-up(md) {
            
            margin-left: 15px;
            right: auto;
        }

        span {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 6px;
            left: 5px;
            text-align: center;
            line-height: 26px;
            font-weight: 600;
            font-size: 10px;
            color: #000;
        }
    }

    &:hover {
        text-decoration: none;
        color: $primary !important;

        .shopping_cart_dropdown {
            //display: none;
            visibility: visible;
            text-align: left;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    .dropdown-toggle:after {
        display: none;
    }

    .dropdown-menu {
        border: 0 !important;
        padding: 0 !important;
        margin: 10px 0 0 0;
        border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        //right: 0;
        left: auto;

        @include media-breakpoint-up(lg) {
            left: -85px;
        }

        li {
            padding: 0;
            margin: 0;
        }


    }
    .shopping_cart_dropdown {
        padding: 1rem;
        margin: 1rem auto;
        width: 250px;
        background: #fff;
        top: 100%;
        @include transition($transition-base);
        @include media-breakpoint-up(md) {
            position: static !important;
            margin: 0;
            z-index: 700;
        }
    }
}

.shopping_cart_dropdown {
    //display: none;   
    left: auto;
    position: relative;
    right: 0;
    z-index: 999;
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            width: 100%;
            float: none;
            padding: 0.5rem 0;
            border-bottom: 1px solid $primary;
            line-height: normal;

            span.quantity {
                font-size: 12px;
                display: block;
            }
        }
    }

    .total {
        text-align: center;
        margin-top: 15px;
    }

    .woocommerce-mini-cart__buttons {
        background: none !important;
        border: 0 !important;
        box-shadow: none !important;
        padding: 0 !important;
        margin: 0;
        margin: 0;
        text-align: center;
        width: 100%;

        a {
            margin: 1rem auto 0 auto;
            text-align: center;
            padding: 0.5rem 1rem !important;
            display: block;
            &:hover, &:focus {
                text-decoration: none;

            }
        }
    }

    .cart_list a>img,
    .mini_cart_item img {
        max-width: 60px !important;
        width: 60px !important;
        height: auto !important;
        float: left !important;
        padding: 0 10px 0 0 !important;
        display: block !important;
        margin: 0 !important;
    }

    .mini_cart_item {
        position: relative;

        .remove {
            display: none !important;
        }

        .mini-product {
            clear: both;
            margin: 0;
            color: $black;
        }

        .title-p {
            width: 158px;
            float: left;
            font-weight: bold;
            line-height: 1.1 !important;
        }

        .quantity {
            padding-left: 60px;
            color: $body-color;
        }
    }

    .checkout {
        display: none;
    }
}

.woocommerce nav.woocommerce-pagination ul {
    display: flex !important;
    justify-content: center !important;
    margin: 2rem 0 0 0 !important;
    border: 0 !important;
    li {
        &:last-child {
            border: 0;
        }
    }
}

.woocommerce nav.woocommerce-pagination ul li {}

.novetat {
    margin: 15px 0;
}

// Products list
.woocommerce .products ul,
.woocommerce ul.products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px 0 -15px !important;
    overflow: hidden;

    &:after,
    &:before {
        content: unset !important;
    }

    li.product {
        width: calc(100% - 30px) !important;
        margin: 15px !important;

        @include media-breakpoint-up(md) {
            width: calc(50% - 30px) !important;
        }

        @include media-breakpoint-up(lg) {
            width: calc(33.33332% - 30px) !important;
        }
    }
}

.slide-products {
    .product {
        width: 100%;
    }

    .owl-dots {
        margin-top: 1rem;
    }
}

.products {
    .product {
        padding: 0;
        
        position: relative;
        
        text-align: left;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        background: linear-gradient(180deg, rgba($gray-100, 1) 0%, rgba($gray-300, 1) 100%);
        transition: all .2s ease-in-out; 
        &:hover, &:focus {
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
            background: linear-gradient(180deg, rgba($gray-200, 1) 0%, rgba($gray-400, 1) 100%);
            
        }


        h3,
        .woocommerce-loop-product__title {
            color: $primary;
            font-size: 1.7rem !important;
            font-weight: bold;
            padding:  0 !important;
            margin: 1rem 1rem 0 1rem!important;
            font-family: $font-family-sans-serif;
        }


        .price{
            font-size: 1.5rem !important;
            color: $body-color !important;
            display: block;
            font-weight: bold !important;
            margin: 1rem !important;
        }
        .amount, .amount bdi {
            display: flex;

            font-weight: bold !important;
            .woocommerce-Price-currencySymbol  {
                    display: flex;
                    align-items: center;
                &:after {
                    content: '+shipping';
                    font-size: 14px;
                    font-weight: normal;
                    padding-left: 5px;
                    &:lang(es_ES) {
                        content: '+envío';
                    }
                }
                
            }
        }



        .button {
            margin: auto auto 1rem 1rem !important;
            font-weight: normal;
            text-transform: uppercase;

        }

        a.added_to_cart {
            padding: 0 1rem 1rem 1rem;
        }
        
    }
}

:lang(es) {
    .product {
        .amount {
            .woocommerce-Price-currencySymbol  {
                &:after {
                    content: '+envío';
                }
            }
        }
    }
}

.summary {
    h1 {
        font-weight: 600;
        color: $primary;
        padding-bottom: 1rem !important
    }

    .price {
        font-size: 2rem !important;
        color: $primary !important;
        display: block;
        font-weight: 400;
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        //border-bottom: 1px solid $gray-300;
    }
}


 .woocommerce-cart table.cart img {
     width: 150px !important;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
    color: $primary;
    font-size: 1.7rem !important;
}

.woocommerce div.product p.price del, .woocommerce div.product span.price del, .woocommerce ul.products li.product .price del {
    display: block !important;
}

.woocommerce div.product p.price ins, .woocommerce div.product span.price ins, .woocommerce ul.products li.product .price ins {
    text-decoration: none !important;
    font-weight: normal !important;
}

.woocommerce ul.products li.product .onsale {
    margin: 0 !important;
}
.woocommerce span.onsale {
    background-color: red !important;
    border-radius: 0 !important;
    left: 0 !important;
    right: auto !important;
    top: 0 !important;
}

.single-product.woocommerce span.onsale {
    //left: 15px !important;
}


.woocommerce span.soldout {
    min-height: 3.236em;
    min-width: 3.236em;
    padding: .202em 1rem;
    font-size: 1em;
    font-weight: 700;
    position: absolute;
    text-align: center;
    line-height: 3.236;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0;
    background-color: $primary;
    color: #fff;
    font-size: .857em;
    z-index: 9;
}

.woocommerce div.product .stock {
    color: $primary  !important;
}

a .foto-image {
    &:hover, &:focus {
    img {
            transform: scale(1.1); 
        } 
    }   
}
.foto-image {
    width: 100%;
    display: block;
    padding: 0;
    overflow: hidden;
    margin: 0;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-color: #222;
    &:before {
        padding-top: 91%;
        display: block;
        content: "";
    }
    .vendidos {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-shadow:
            1px 1px 0px rgba(0,0,0,.2),
            2px 2px 0px rgba(0,0,0,.4),
            3px 3px 0px rgba(0,0,0,.5);
        
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        border: 0;
        transition: all .2s ease-in-out; 
    }
    
    a {
        display: block;

    }

    .play {
    	left: 50%;
	    top: 50%;
	    bottom: auto;
	    right: auto;
        transform: translateX(-50%) translateY(-50%);
        width: 60px;
        height: 60px;
        background-color: transparent;
        background-image: url('../assets/img/play.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        position: absolute;
        text-align: center;
        z-index: 30;
    }
}

.resum {
    p {
        margin: 0;
    }
}

.bg-special {
    background-color: #fff;
    background: linear-gradient(180deg, rgba($gray-100, 1) 0%, rgba($gray-300, 1) 100%);
    transition: all .2s ease-in-out; 
    &:hover, &:focus {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
        background: linear-gradient(180deg, rgba($gray-200, 1) 0%, rgba($gray-400, 1) 100%);
        text-decoration: none;
    }
    a {
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.button {
    border-radius: 0 !important;
    //background-color: none !important;
    background-color: $primary !important;
    //border: 2px solid $primary !important;
    color: $white !important;
    transition: 0.25s !important;
    &:hover,
    &:focus {
        background-color: $secondary !important;
        color: $white !important;
        //border-color: $secondary !important;
        //box-shadow: inset 0 0 0 2em $secondary !important;

    }
}

.page-title {
    margin-top: 0;
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.product_title.entry-title {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.cart_totals h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.col2-set {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 2rem;

    .col-1 {
        float: left;
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .col-2 {
        float: left;
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

// forms
.woocommerce-checkout,
.woocommerce-checkout-payment,
.woocommerce-cart-form {
    margin-bottom: 2rem;
}

.woocommerce form .form-row {
    padding: 0;
    margin: 0 -5px;
    flex-direction: column;
    width: 100%;
}

.select2-container--default .select2-selection--single {
    height: calc(1.5em + .75rem + 2px) !important;
    padding: .375rem 1.3rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 5px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 0 !important;
    line-height: normal !important;
}

.woocommerce form .form-row textarea {
    min-height: 200px;
}





/* Style WC Account Endpoint Links */
nav.woocommerce-MyAccount-navigation ul {
  list-style-type: none;
  padding-left: 0;

  font-size: 17px;
  line-height: 26px;
}
nav.woocommerce-MyAccount-navigation ul li {
  padding: 8px 20px;
  background-color: rgba(0,0,0,0.05);
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
nav.woocommerce-MyAccount-navigation ul li.is-active {
  background-color: rgba(0,0,0,0.1);
}
nav.woocommerce-MyAccount-navigation ul li.is-active a {
  color: rgba(0,0,0,0.8); cursor: default;
}
nav.woocommerce-MyAccount-navigation ul li.is-active:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 14px solid rgba(0,0,0,0.1);
  border-bottom: 20px solid transparent;
  float: right;
  margin-right: -34px;
  margin-top: -7px;
}
nav.woocommerce-MyAccount-navigation ul li:not(.is-active):hover {
  background-color: rgba(0,0,0,0.07);
}
nav.woocommerce-MyAccount-navigation ul li:not(.is-active):hover:after { 
  content: "";
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 14px solid rgba(0,0,0,0.07);
  border-bottom: 20px solid transparent;
  float: right;
  margin-right: -34px;
  margin-top: -7px;
}


.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
    background-color: $primary !important;
    color: #fff !important;
}