/*------------------------------------*\
  Slide principal
\*------------------------------------*/
.slide-principal {
    .slide {
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
  
        min-height: 380px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            
            height: 0;
            min-height: 0;
            padding-bottom: 41.66666667%;
            position: relative;
        }
    }

    .slide-text {
        text-align: left;
        position: relative;
        z-index: 999;
        text-shadow:
            1px 1px 0px rgba(0,0,0,.2),
            2px 2px 0px rgba(0,0,0,.4),
            3px 3px 0px rgba(0,0,0,.5);

            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
            }
            .btn {
                &:hover, &:focus {
                    background-color: $primary;
                    color: $white;
                    border-color: $primary;
                }
            }


    }

    .owl-nav [class*='owl-'] {
        color: #ffffff !important;
    }

    .owl-nav .owl-prev {
        left: 15px;
    }

    .owl-nav .owl-next {
        right: 15px;
    }

    .owl-dots {
        position: absolute;
		bottom: 0.5rem;
		left: 0;
        right: 0;
        margin: 0;
    }

  

    .owl-item .slide .background {
        opacity: 0;
        transform: translate(0, 100%);
    }


    .owl-item.active .slide .background {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }

    .owl-item .slide .title {
        opacity: 0;
        transform: translate(50%, 0);
    }


    .owl-item.active .slide .title {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }

    .owl-item .slide .animation-2 {
        opacity: 0;
        transform: translate(-100%, 0);
    }



    .owl-item.active .slide .animation-2 {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }
}


.slide-rooms, .slide-servicios, .slide-actividades, .slide-fotos, .slide-noticias, .slide-products  {
    margin: 0;
    clear: both;
   
    .owl-stage {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        
    }
    .owl-item {
        display: flex;
        
    }
    .product-item {
        width: 100%;
        display: flex;
    }

}
