// Page Navi

.two_columns {
    @include media-breakpoint-up(lg) {
        column-count: 2;
        column-gap: 40px;
    }
}



.article{
    position: relative;
    display: flex;
    flex-direction: column;
    background: $white;
    color: $body-color;
    width: 100%;
	a {
		color: $black;
	}
    &:hover, &:focus {
        text-decoration: none;
        color: $white;
        
        &:before {
            height: 100%;
        }
        a {
            color: $white;
        }
    }
  
    &:before{
        content: " ";
        position: absolute;
        height: 0;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: $red;
        z-index: 1;
    }
    &:after {
        content: ' ';
        position:absolute;
        bottom: 0; 
        right:0;
        background: linear-gradient(to left top, transparent 50%, rgba($gray,1) 0) 100% 0 no-repeat;
        width: 2em;
        height:2em;
        transform: rotate(180deg);
        z-index: 2;
    }
    .page {
        position: relative;
        z-index: 3;
    }
    .more-info {
        margin-top: auto;
        margin-bottom: 0;
        position: relative;
        z-index: 3;
        font-size: 2rem;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.article.archive {
    background: $gray;
    margin-top: 30px;
    &:after {
        background: linear-gradient(to left top, transparent 50%, rgba($white,1) 0) 100% 0 no-repeat;
    }
}


.cover {
    width: 100%;
    height: 0;
    min-height: 0;
    padding-bottom: 56.2%;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: $gray;
    border: 1px solid $white;
    overflow: hidden;
    a {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}




#browsing {
    clear: both;
}

.previouspostslink a, .nextpostslink a {
    color: $body-color;
    &:hover, &:focus {
        color: $secondary;
        text-decoration: none;;
    }
}

.wp-pagenavi {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center; 
    position: relative;
    padding:0;
    margin: 2rem 0 0 0;
    color: $body-color;
    clear: both;
    line-height: 1;
    a {
        color: $body-color;
        padding: 0.5em;
        border-right: 1px solid  #d3ced2;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $primary;
        }
    }

    span {
        &:after, &:before {
            //vertical-align: middle;
        }
    }

    .extend {
    }


    .current {
        padding: 0.5em;
        background: #ebe9eb;
        color: #8a7e88;
    }

    .page {
        
    }

    .previouspostslink {
        //border: 0;
    }

    .nextpostslink {
        border: 0;
  
    }
    .menupostslink {
        //font-size: 2rem;
        //line-height: 1;
    }


}






.share-section {
	position: relative;
}

.card__share {
	position: absolute;
	top: -8px;
	left: -320px;
	width: 300px;
	text-align: right;
}

.share-btn {
	color: $body-color;
	span {
		color: $primary;
	}
	&:hover, &:focus {
		text-decoration: none;
		color: $primary;
	}
}

.share-icon {
	margin-right: 0;
	font-size: 20px;
	width: 32px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	background-color: $gray-600;
	color: #fff;
	display: inline-block;
	&.facebook {
		background-color: $facebook;
	}
	&.twitter {
		background-color: $twitter;
	}
	&.whatsapp {
		background-color: $whatsapp;
	}
	&:hover, &:focus {
		background-color: $primary;
		color: $white;
		text-decoration: none;
	}
}
