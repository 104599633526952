

.shadow-bottom {
    height: 30px;
    width: 100%;
    //background: linear-gradient(to bottom, $gray-500, transparent);
    background-image: url('../assets/img/shadow-bottom.png');
    background-size: 30px 30px;
    background-repeat: repeat-x;
    margin-bottom: 2rem;
}


.bg-section {
    background-color: $gray;
}

.custom-bg {
    background-color: $gray;
    position: relative;
    &:after {
        
        content: ' ';
        position:absolute;
        bottom: 0; 
        right:0;
        background: linear-gradient(to left top, transparent 50%, rgba($white,1) 0) 100% 0 no-repeat;
        width: 2em;
        height:2em;
        transform: rotate(180deg);
        z-index: 2;
    }
}

.custom-bg-primary {
    color: $white;
    background: $primary;
    &:before {
        content: " ";
        background-color: $primary;
        opacity: 0.8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block !important;
        mix-blend-mode: multiply;
    }
}
.custom-bg-secondary {
    color: $white;
    background: $secondary;
    &:before {
        content: " ";
        background-color: $secondary;
        opacity: 0.6;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block !important;
        mix-blend-mode: multiply;
    }
}




.triangle-primary {
    width         : 0;
    height        : 0;
    border-left   : 300px solid transparent;
    border-right  : 0 solid transparent;  
    border-bottom : 500px solid $primary;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.8 !important;
    mix-blend-mode: multiply;
    @include media-breakpoint-up(sm) {
        border-left   : 600px solid transparent;
    }
    @include media-breakpoint-up(md) {
        border-left   : 900px solid transparent;
    }
    @include media-breakpoint-up(lg) {
        border-left   : 1200px solid transparent;
    }

    @include media-breakpoint-up(xl) {
        border-left   : 1500px solid transparent;
    }
    &.rojo {
        border-bottom-color :  $primary !important;
    }
    &.amarillo {
        border-bottom-color :  $secondary !important;
    }
    &.azul {
        border-bottom-color :  $blue !important;
    }
}


.triangle-secondary {
    width         : 0;
    height        : 0;
    border-left   : 300px solid transparent;
    border-right  : 0 solid transparent;  
    border-bottom : 300px solid $secondary;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.8 !important;
    mix-blend-mode: multiply;
    @include media-breakpoint-up(sm) {
        border-left   : 600px solid transparent;
    }
    @include media-breakpoint-up(md) {
        border-left   : 900px solid transparent;
    }
    @include media-breakpoint-up(lg) {
        border-left   : 1200px solid transparent;
    }

    @include media-breakpoint-up(xl) {
        border-left   : 1500px solid transparent;
    }
    &.rojo {
        border-bottom-color :  $primary !important;
    }
    &.amarillo {
        border-bottom-color :  $secondary !important;
    }
    &.azul {
        border-bottom-color :  $blue !important;
    }
    
}



.custom-bg-image {
    width: 100%;
    min-height: 300px;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: $gray;
    @include media-breakpoint-up(lg) {
        height: 0;
        min-height: 0;
        padding-bottom: 25%;
    }
}


.bg-image {
    width: 100%;
    height: 0;
    min-height: 0;
    padding-bottom: 56.2%;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: $gray;
}
