.cajas {
    .wpb_wrapper {
        height: 100%;
        display: flex;

    }
}
.cajas-column {
    .wpb_wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

    }
}
.caja{
    position: relative;
    display: flex;
    flex-direction: column;
    background: $white;
    color: $body-color;
    width: 100%;
    padding-top: 3rem !important;
    //height: 100%;
    &:hover, &:focus {
        text-decoration: none;
        color: $white;
        &:before {
            height: 100%;
        }
        a {
            color: $white;
        }
    }
  
    &:before{
        content: " ";
        position: absolute;
        height: 20px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: $red;
        z-index: 1;
        @include transition($transition-base);
    }
    &:after {
        
        content: ' ';
        position:absolute;
        bottom: 0; 
        right:0;
        background: linear-gradient(to left top, transparent 50%, rgba($white,1) 0) 100% 0 no-repeat;
        width: 2em;
        height:2em;
        transform: rotate(180deg);
        z-index: 2;
    }
    .page {
        position: relative;
        z-index: 3;
    }
    
}


.more-info {
    margin-top: auto;
    margin-bottom: 0;
    position: relative;
    z-index: 3;
    font-size: 2rem;
    &:hover, &:focus {
        text-decoration: none;
    }
}

.caja-roja {
    &:before {
        background-color: $red;
    }
}

.caja-amarilla {
    &:before {
        background-color: $yellow;
    }
}

.caja-azul {
    &:before {
        background-color: $blue;
    }
}

.caja-gris {
    background: $gray;
    &:before {
        background-color: $gray-600;
    }
}


.caja-historia {
    position: relative;
    display: flex;
    flex-direction: column;
    background: trasnparent;
    color: $body-color;
    width: 100%;
    &:hover, &:focus {
        text-decoration: none;
        //color: $white;
        &:before {
            //height: 100%;
        }
        a {
            //color: $white;
        }
        .text-primary, .text-secondary {
            //color: $white !important;
        }
    }
  
    &:before{
        content: " ";
        position: absolute;
        height: 20px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: $red;
        z-index: 1;
        @include transition($transition-base);
    }
    /*
    &:after {
        
        content: ' ';
        position:absolute;
        bottom: 0; 
        right:0;
        background: linear-gradient(to left top, transparent 50%, rgba($white,1) 0) 100% 0 no-repeat;
        width: 2em;
        height:2em;
        transform: rotate(180deg);
        z-index: 2;
    }*/
    .text-secondary {
        text-shadow:
            1px 1px 0px rgba(0,0,0,.2),
            2px 2px 0px rgba(0,0,0,.4),
            3px 3px 0px rgba(0,0,0,.5);
    }
    .page {
        position: relative;
        z-index: 3;
    }
    &.rojo {
        &:before{
            background-color: $red;
        }
    }
    &.amarillo {
        &:before{
            background-color: $yellow;
        }
    }
    &.azul {
        &:before{
            background-color: $blue;
        }
    }
    
}



.box-left {
    background-color: $secondary;
    margin-bottom: 50px;
    position: relative;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(md) {
        width: calc(100% - 25px);
        margin-right: 25px;
        margin-bottom: 0;
    }
    .flecha {
        width: 0;
        height: 0;
        margin-left: -25px;
        position: absolute;
        bottom: -50px;
        left: 50%;
        border: 25px solid;
        border-color: $secondary transparent transparent transparent; 
        @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-top: -25px;
            top: 50%;
            right: -50px;
            left: auto;
            bottom: auto;
            border: 25px solid;
            border-color: transparent transparent transparent $secondary; 
        }
    }
}

.box-right {
    background-color: $secondary;
    margin-top: 50px;
    position: relative;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(md) {
        width: calc(100% - 25px);
        margin-left: 25px;
        margin-top: 0;
    }
    .flecha {
        width: 0;
        height: 0;
        margin-left: -25px;
        position: absolute;
        top: -50px;
        left: 50%;
        border: 25px solid;
        border-color: transparent transparent $secondary transparent; 
        @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-top: -25px;
            top: 50%;
            left: -50px;
            border       : 25px solid;
            border-color : transparent $secondary transparent transparent; 
        }
    }
}


.box-secondary {
    
    background-color: $secondary;
    position: relative;
    padding: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    &:after {
        
        content: ' ';
        position:absolute;
        bottom: 0; 
        right:0;
        background: linear-gradient(to left top, transparent 50%, rgba($white,1) 0) 100% 0 no-repeat;
        width: 2em;
        height:2em;
        transform: rotate(180deg);
        z-index: 2;
    }


}



.single-image {
    position: relative;
    width: 100%;
    display: block;
    padding: 0;
    overflow: hidden;
    margin: 0 0 20px 0;
    position: relative;
    &:before {
        padding-top: 91%;
        display: block;
        content: "";
    }
    &:after {
        
        content: ' ';
        position:absolute;
        bottom: 0; 
        right:0;
        background: linear-gradient(to left top, transparent 50%, rgba($white,1) 0) 100% 0 no-repeat;
        width: 2em;
        height:2em;
        transform: rotate(180deg);
        z-index: 2;
    }
    .vendidos {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-shadow:
            1px 1px 0px rgba(0,0,0,.2),
            2px 2px 0px rgba(0,0,0,.4),
            3px 3px 0px rgba(0,0,0,.5);
        
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        border: 0;
        transition: all .2s ease-in-out; 
    }

}




.intro-table {
	color: $white;
	min-height: 300px;
	position: relative;
	.owl-carousel {
		position: unset;
    }


	.btn {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		
	}
}


.bg-blue {
    background-color: $blue;
}
.bg-indigo {
    background-color: $indigo;
}
.bg-purple {
    background-color: $purple;
}
.bg-pink {
    background-color: $pink;
}
.bg-red {
    background-color: $red;
}
.bg-orange {
    background-color: $orange;
}
.bg-yellow {
    background-color: $yellow;
}
.bg-green {
    background-color: $green;
}
.bg-teal {
    background-color: $teal;
}
.bg-cyan {
    background-color: $cyan;
}

