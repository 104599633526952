body {
    //color: $gray-700;
}

img {
    max-width: 100%;
    height: auto;
}

h1, .h1 { font-family: $font-title; }
h2, .h2 { font-family: $font-title; }
h3, .h3 { font-family: $font-title; }
h4, .h4 { font-family: $font-title; }
h5, .h5 { font-family: $font-title; }
h6, .h6 { font-family: $font-title; }

// security
.grecaptcha-badge { visibility: hidden; }

.subtitle {
    font-family: $font-family-sans-serif;
    font-weight: normal;
    font-style: italic;
}

.font-title {
    font-family: $font-family-sans-serif;
}

.font-weight-bold, strong, b, th {
  
}

.mb-grid {
    margin-bottom: 20px;
}

.wpb_text_column ul, .caja .page ul, .box-secondary .page ul {
   list-style: none; /* Remove default bullets */
    padding-left: 1rem;
    margin: 1rem 0;
}

.wpb_text_column  ul li::before, .caja .page ul li::before, .box-secondary .page ul li::before  {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-family: $font-title; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.um-form ul li::before{
    content: '';
    display: none;
}

@keyframes blurFadeIn {
    0% {
        opacity: 0;
        //transform: scale(1.3);
    }

    /*
    50% {
        opacity: 0.5;
        transform: scale(1.1);
    }
    */
    100% {
        opacity: 1;
        //transform: scale(1);
    }
}

.fade-in-element {
    animation: blurFadeIn 1.4s ease-in backwards;
}

.table td, .table th {
    border-top: 1px solid $primary;
}
.table thead th {
    border-top: 0;
    border-bottom: 1px solid $primary;
    text-transform: uppercase;
}



.btn-min {
    min-width: 200px;
    text-transform: uppercase;
    font-family: $font-title;
}

.btn-outline-dark {
    &:hover, &:focus {
        background-color: $primary;
        border-color: $primary;
    }
}

.btn-primary {
    background-color: $primary;
    border-color:  $primary;
    color: $white;
    transition: 0.25s !important;
    &:hover, &:focus {
        background-color: $secondary !important;
        border-color:  $secondary !important;
        color: $white !important;
    }
}


.content {
    overflow-x: hidden;
    img {
        max-width: 100%;
        height: auto;
    }
}



.icono_texto {
    border-bottom: 5px solid $white;
    padding-bottom: 1.5rem;
    .image {
        img {
            max-width: 60%;
            margin: 0 auto 1.5rem auto;
        }
    }
    .text {

    }
}


.logos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .logo {
        width: 50%;
        height: auto;  
        @include media-breakpoint-up(md) {
            width: 25%;
        }  
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.logos-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    img {
        width: 50%;
        height: auto;  
    }
}


.map-item {
    width: 100%;
    height: 500px;
}




.error-404 {
    width: 100%;
    height: calc(100vh - 150px);
    background-color: $primary;
    display: flex;
    align-items: center;
}

.mfp-iframe-holder .mfp-content {
    //max-width: 100%;
}