// Color system
$primary:  #000000;
//$secondary:  #343a40;
$secondary:  #ee3c44;
$hover:  #343a40;
$gray: #f1f1f1 !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$blue:    #102266 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #ee3c44 !default;
$orange:  #fd7e14 !default;
$yellow:  #fadc13 !default;
$green:   #33cc33 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


$facebook: #3B5998;
$twitter: #61c5ec;
$whatsapp: #4acb59;
$github: #000000;
$linkedin: #0671ae;
$youtube: #f70100;
$instagram: #cf2f79;

// Fonts


@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,900,900i|Roboto:400,400i,700,700i&display=swap');

$font-family-sans-serif:      'Roboto', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-title:  'Merriweather', serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;;


// Font size
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.1 !default;
$font-size-sm:                $font-size-base * .875 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;


$lead-font-size:              $font-size-base * 1.15 !default;
$small-font-size:             80% !default;


// Style anchor elements.
$link-color:      $primary !default;
$link-decoration:   none !default;
$link-hover-color:   $hover !default;

// Grid
$grid-gutter-width:  30px !default;


// Define common padding and border radius sizes and more.
$border-radius:               0 !default;
$border-radius-lg:            0 !default;
$border-radius-sm:            0 !default;


// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0 !default;
$btn-border-radius-lg:        0 !default;
$btn-border-radius-sm:        0 !default;


// For each of Bootstrap's buttons, define text, background, and border color.

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         1.3rem !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      1rem !default;

$input-btn-padding-y-lg:      .7rem !default;
$input-btn-padding-x-lg:      1.8rem !default;


$btn-padding-y:               0.375rem !default;
$btn-padding-x:               1.5rem !default;


$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;


$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;