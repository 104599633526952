/* menu-footer */
.footer {
    margin-top: 2rem;
    text-align: left;
    @include media-breakpoint-up(sm) {
        text-align: left;
    }
    .logo {
        width: 150px !important;
        height: auto !important;
    }
    a {
        color: $body-color;
        &:hover, &:focus {
            text-decoration: none;
            color: $primary;
        }
    }
    .caja-widget {
     
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: 100%;
    }


    .menu {
        list-style: none;
        margin: 0;
        padding: 0;
        
    }


    .social {
        
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            font-size: 2rem;
            display: inline;
            position: static;
            margin: 0;
            padding: 0 10px 10px 0;
            //color: $gray-600;
            a {
                //color: $gray-600;
                text-decoration: none;
                &:hover, &:focus {
                    color: $primary;
                }
            }
            &.facebook a:hover, &.facebook a:focus {
                color: $facebook !important;
            }
            &.twitter a:hover, &.twitter a:focus   {
                color: $twitter;
            }
            &.linkedin a:hover, &.linkedin a:focus   {
                color: $linkedin;
            }
            &.youtube a:hover, &.youtube a:focus   {
                color: $youtube;
            }
            &.instagram a:hover, &.instagram a:focus   {
                color: $instagram;
            }
        }
        
    }

    .legal-footer {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline;
            position: static;
            margin-top: 0;
            padding: 0 0 0 15px;
            a {
                text-decoration: none;
              
                &:hover, &:focus {
                    text-decoration: underline;
                    
                }
            }
        }
    }
}